import React from "react"
import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import { graphql } from "gatsby"
import Hero from "../sections/page-specific/licenses/Hero"
import TradeLicenses from "../sections/page-specific/licenses/TradeLicenses"
import Requirements from "../sections/page-specific/licenses/Requirements"
import DarkGroup from "../components/common/DarkGroup"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function Licences(props) {
  return (
    <Layout>
      <DarkGroup>
        <Hero />
        <TradeLicenses />
      </DarkGroup>
      <Requirements />
    </Layout>
  )
}

export default Licences

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
    }
  }
`
